import { MetaFunction, json } from "@remix-run/node";
import { BackpackIcon, LockClosedIcon } from "@radix-ui/react-icons";
import { Link, useLoaderData } from "@remix-run/react";
import { Conference, isEventOngoing, isEventOver } from "~/schema.server";
import { db } from "~/db.server";
import { eq } from "drizzle-orm";
import { cn } from "~/lib/utils";

export async function loader() {
  const conference = await db.query.Conference.findFirst({
    where: eq(Conference.active, true),
    // orderBy: sorter.queryFromRequest(request),
  });

  if (!conference) {
    throw new Response("Conference not found", { status: 404 });
  }

  return json({
    conference,
    isRegistrationOpen: isEventOngoing(conference.events.schoolRegistration),
  });
}

export const meta: MetaFunction = () => {
  return [
    { title: "Registration - MUNOG" },
    {
      name: "description",
      content: "Register your school for MUNOG here.",
    },
  ];
};

function TimeLeftIndicator({
  event,
  className,
}: {
  event: { end: Date | string; isEstimated?: boolean };
  className?: string;
}) {
  const end = event.end instanceof Date ? event.end : new Date(event.end);

  const diffInDays = (end.getTime() - Date.now()) / (1000 * 60 * 60 * 24);
  const displayText = diffInDays > 1 ? "days" : "day";
  const displayValue = Math.floor(diffInDays);

  return (
    <span className={cn("text-sm text-slate-200", className)}>
      {event.isEstimated ? "About " : ""}
      {displayValue} {displayText} left
    </span>
  );
}

export default function Index() {
  const { conference, isRegistrationOpen } = useLoaderData<typeof loader>();

  return (
    <div className="bg-no-repeat bg-cover bg-gradient-to-tl from-black via-slate-900 to-slate-800 min-h-screen pb-12">
      <div className="max-w-md mx-auto">
        <h1 className="text-2xl tracking-wider border-b border-slate-600 p-4 text-center uppercase">
          MUNOG
        </h1>
      </div>
      <main className="max-w-lg mx-auto mt-12">
        {isRegistrationOpen && (
          <>
            <div className="flex gap-2 items-center">
              <h2 className="my-4 px-3 text-lg font-semibold">Registration</h2>
              <TimeLeftIndicator
                className="bg-slate-400/10 rounded-lg px-4 py-1"
                event={conference.events.schoolRegistration}
              />
            </div>

            <section className="mt-8">
              <Link
                viewTransition
                to="/register/school"
                className="block group overflow-clip relative [view-transition-name:register-box-school] border bg-slate-800 border-slate-700 hover:bg-slate-700 hover:border-slate-600 rounded shadow-lg p-4 "
              >
                {/* <img
              className="absolute opacity-0 group-hover:opacity-100 group-hover:scale-110 transition-all duration-300 -top-32 left-0 w-full [filter:brightness(0.3)]"
              src="https://images.unsplash.com/photo-1555848962-6e79363ec58f?q=80&w=2033&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              alt=""
            /> */}
                <div className="text-lg relative [view-transition-name:headline]">
                  <BackpackIcon className="inline-block h-5 w-5 mr-4" />
                  Register Delegation
                </div>
                <div className="mt-1 z-10 relative">
                  Register your school to participate in the MUNOG conference.
                  You can also register chairs here.
                </div>
              </Link>
            </section>
          </>
        )}
        {/* <section className="mt-8">
          <Link
            unstable_viewTransition
            to="/register/chair"
            className="block [view-transition-name:register-box-chair] border bg-slate-800 border-slate-700 rounded shadow-lg p-4 hover:bg-slate-700 hover:border-slate-600"
          >
            <div className="text-lg">
              <PersonIcon className="inline-block h-5 w-5 mr-4" />
              Register Chair
            </div>
            <p className="mt-1">
              Register to be a chair for the Munog conference.
            </p>
          </Link>
        </section> */}

        <h2 className="mt-8 my-4 px-3 text-lg font-semibold">
          Already registered?
        </h2>
        <section>
          <Link
            to="/login"
            className="block underline text-lg p-4 rounded-lg hover:bg-slate-200/10"
          >
            <LockClosedIcon className="inline-block h-5 w-5 mr-4 " />
            Login
          </Link>
        </section>
      </main>
    </div>
  );
}
